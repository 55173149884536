import React from "react"
import Layout from "../components/Layout"
import { Helmet } from "react-helmet"
import loadable from "@loadable/component"

// Lazy load the Services component
const Services = loadable(() => import("../components/Services-Page/"))

export default function ServicesPage() {
  return (
    <Layout>
      <Helmet>
        <link rel="canonical" href="https://codefulcrum.com/services/" />
      </Helmet>
      <Services />
    </Layout>
  )
}
